@import "../../styles/variables";

.hero {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-top: size(8);
	margin-bottom: size(24);

	background-color: $colour-grey-lightest;
	border-radius: size(20);
	overflow: hidden;

	@media #{$sm-viewport-down} {
		flex-direction: column;
	}

	> div:first-child {
		padding: size(64) size(80);
		max-width: size(768);

		@media #{$md-viewport-down} {
			padding: size(64*0.5) size(80*0.5);
		}
	}

	em {
		font-size: size(24);
		font-weight: 500;
		font-style: normal;
		line-height: normal;

		opacity: 0.5;
	}

	h1 {
		margin-bottom: size(4);
	}

	p {
		font-size: size(20);
		opacity: 0.5;
	}

	> figure {
		z-index: 2;
		align-self: stretch;
		width: 50%;
		
		mix-blend-mode: multiply;

		@media #{$md-viewport-down} {
			width: 100%;
			height: auto !important;
		}

		span,
		img {
			display: block !important;
			width: 100% !important;
			height: 100% !important;
			object-fit: cover;
			object-position: top right;
			vertical-align: middle;
		}
	}
}

.black {
	color: $colour-white;

	background-color: $colour-black;
}

.containImage {
	> figure {
		margin: auto size($spacer) auto 0;
		align-self: center;
		max-width: size(300);

		span,
		img {
			object-fit: contain;
		}
	}
}
